import React, { useState, useEffect, useContext } from 'react'
import Button from '../../../../Static/Button'
import Pagination from '../../../../Static/Pagination'
import { FaPlay } from 'react-icons/fa'
import { myContext } from '../../../../../Context'
import axios from 'axios'
import toast from 'react-hot-toast'
import { MdEdit, MdDeleteForever } from 'react-icons/md'
import pfp from '../../../../../assets/images/Default_pfp.svg.png'
import EditVideoForm from './EditVideoForm'

function NotApprovedVideos({ token }) {
    const { themeMode } = useContext(myContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [confirmModal, setConfirmModal] = useState(false)
    const [editModal, setEditModal] = useState({ showModal: false, id: null });
    const [videos, setVideos] = useState([])
    const [loading, setLoading] = useState(false)
    const videosPerPage = 10

    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(videos.length / videosPerPage); i++) {
        pageNumbers.push(i)
    }

    const indexOfLastVideo = currentPage * videosPerPage
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage
    const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo)

    const playVideo = (videoURL) => {
        setSelectedVideo(videoURL)
    }

    const getDisapprovedVideos = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/video/unapprovedVideos`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (res.status === 200) {
                const newVideos = res.data.videos;
                setVideos(newVideos);

                const newPageNumbers = Math.ceil(newVideos.length / videosPerPage);
                if (currentPage > newPageNumbers) {
                    setCurrentPage(newPageNumbers);
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const approveVideo = async (id) => {
        try {
            const res = await axios.put(process.env.REACT_APP_ENDPOINT + `/video/approveVideo/${id}`, {}, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Video Approved!');
                getDisapprovedVideos();
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getDisapprovedVideos()
    }, [])

    const deleteVideo = async (videoId, trainerId) => {
        setLoading(true)
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_ENDPOINT}/video/deleteVideo`,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        videoId: videoId,
                        trainerId: trainerId,
                    },
                }
            );

            if (res.status === 200) {
                toast.success('Video Deleted!');
                setConfirmModal(false)
                getDisapprovedVideos();
                setLoading(false)
            }
        }
        catch (error) {
            setLoading(false)
            console.error(error);
        }
    }

    const handleEditModal = (id) => {
        setEditModal({ showModal: true, id });
    }

    return (
        <div className='flex flex-col w-full justify-start gap-10'>
            {videos.length === 0 && <p className='text-lg text-center p-10'>There are no videos which are pending approval</p>}
            {currentVideos.map((video, index) => (
                !video.approved ? (
                    <div key={index} className='flex gap-10 items-start'>
                        {selectedVideo === video.videoURL ? (
                            <video
                                className='rounded-2xl w-[300px] cursor-pointer'
                                src={video.videoURL}
                                controls
                            />
                        ) : (
                            <div
                                className='rounded-2xl max-w-[300px] cursor-pointer relative'
                                onClick={() => playVideo(video.videoURL)}
                            >
                                <div className='absolute z-10 w-full flex justify-center items-center h-full'>
                                    <FaPlay className=' text-gray-200 text-5xl' />
                                </div>
                                <div className='relative'>
                                    <img className='w-full h-full' src={video.thumbnailURL} alt="Thumbnail" />
                                </div>
                            </div>
                        )}
                        <div className='flex justify-between items-start w-full'>
                            <div className='flex flex-col gap-1 w-2/3'>
                                <p className='text-2xl font-bold'>{video.title}</p>
                                <div className='flex gap-2 items-center my-2'>
                                    <img src={video.trainer.profilePhoto ? video.trainer.profilePhoto : pfp} alt="Trainer" className='w-10 h-10 rounded-full' />
                                    <p className='text-lg'>{video.trainer.firstName} {video.trainer.lastName}</p>
                                </div>
                                <p className='text-lg'>{video.categories}</p>
                                <p className='text-md pl-2 overflow-hidden overflow-ellipsis max-w-[300px] whitespace-normal break-words'>
                                    {video.description}
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-3 justify-end'>
                            <Button text='Approve' onClick={() => approveVideo(video._id)} className='px-5 py-2 rounded-xl bg-green-500 text-white' />
                            <Button icon={<MdEdit />} onClick={() => handleEditModal(video._id)} className='px-5 py-2 rounded-xl bg-blue-500 text-white' />
                            <Button icon={<MdDeleteForever />} onClick={() => setConfirmModal(!confirmModal)} className='px-5 py-2 rounded-xl bg-red-500 text-white' />
                        </div>
                        {video.approved.length <= 0 && <p className='text-lg'>No videos to show</p>}
                        {confirmModal && (
                            <div className='fixed bg-[rgba(0,0,0,0.1)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                                <div className='flex justify-center items-center h-full'>
                                    <div className={`py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-50'} `}>
                                        <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                                        <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                                        <div className='flex gap-5 mt-10 justify-end'>
                                            {
                                                loading === true ? (
                                                    <>
                                                        <Button disabled={true} text={"Please wait..."} />
                                                        <Button disabled={true} text={"Cancel"} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button onClick={() => deleteVideo(video._id, video.trainer._id)} text={"Confirm"} />
                                                        <Button onClick={() => setConfirmModal(false)} text={"Cancel"} />
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null
            ))}
            {videos.length !== 0 &&
                <Pagination
                    pageNumbers={pageNumbers}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            }
            {
                editModal.showModal === true && <EditVideoForm editModal={editModal} setEditModal={setEditModal} token={token} getVideoss={getDisapprovedVideos} />
            }
        </div>
    )
}

export default NotApprovedVideos