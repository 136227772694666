import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import pfp from '../../../../../assets/images/Default_pfp.svg.png';
import Button from '../../../../Static/Button';
import { CiSearch } from 'react-icons/ci';
import toast from 'react-hot-toast';
import { MdDeleteForever, MdAccountCircle, MdEdit, MdAdd } from 'react-icons/md';
import { myContext } from '../../../../../Context';
import AddTrainerForm from './AddTrainerForm';
import Pagination from '../../../../Static/Pagination';

export default function TrainersList({ token, handleItemClick }) {
    const { themeMode } = useContext(myContext);
    const [trainers, setTrainers] = useState([]);
    const [search, setSearch] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const trainersPerPage = 6;

    const getTrainers = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/auth/getTrainers`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setTrainers(res.data.trainers);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getTrainers();
    }, []);

    const handleViewAccount = (id) => {
        localStorage.setItem('trainerId', id);
        handleItemClick('Trainer Profile');
    };

    const handleModalClick = (id) => {
        handleDeleteAccount(id);
        setConfirmModal(!confirmModal);
    };

    const handleDeleteAccount = async (id) => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/auth/deleteTrainer/${id}`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Trainer account deleted successfully!');
                getTrainers();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const filteredTrainers = trainers.filter((trainer) => {
        const fullName = `${trainer.firstName} ${trainer.lastName}`;
        return fullName.toLowerCase().includes(search.toLowerCase()) || trainer.email.toLowerCase().includes(search.toLowerCase());
    });

    const indexOfLastTrainer = currentPage * trainersPerPage;
    const indexOfFirstTrainer = indexOfLastTrainer - trainersPerPage;
    const currentTrainers = filteredTrainers.slice(indexOfFirstTrainer, indexOfLastTrainer);
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(filteredTrainers.length / trainersPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className={`w-full ${currentTrainers.length <= 4 ? 'h-screen' : 'h-full'} overflow-hidden py-24 px-7`}>
            <div className={`transition-all duration-300 ease-in-out px-7 py-5 rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50'}`}>
                <div className="flex flex-row justify-between items-center">
                    <div className="flex items-center text-lg">
                        <input
                            className="border pl-8 py-1 rounded-xl text-black"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <label className="absolute pl-2 text-black">
                            <CiSearch />
                        </label>
                    </div>
                    <div className="flex flex-row justify-end items-center">
                        <Button text="Add Trainer" icon={<MdAdd className="text-2xl" />} onClick={() => setAddModal(true)} />
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center mt-10">
                    {currentTrainers.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="text-center py-20 text-xl">
                                No trainer admins found.
                            </td>
                        </tr>
                    ) : (
                        currentTrainers.map((trainer, index) => (
                            <div
                                key={index}
                                className={`flex flex-row justify-between items-center w-full py-3 px-5 rounded-xl my-2 transition-all duration-300 ease-in-out ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'
                                    }`}
                            >
                                <div className="flex flex-row items-center ">
                                    <img src={trainer.photoURL ? trainer.photoURL : pfp} alt={trainer.name} className="w-16 h-16 rounded-full mr-5" />
                                    <div className="flex flex-col justify-center items-start text-xl">
                                        <p>
                                            {trainer.firstName} {trainer.lastName}
                                        </p>
                                        <p>{trainer.email}</p>
                                        <p>Experience: {trainer.experience} years</p>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-end gap-3 items-center">
                                    <Button icon={<MdAccountCircle className="text-2xl" />} onClick={() => handleViewAccount(trainer._id)} />
                                    <Button icon={<MdDeleteForever className="text-2xl" />} onClick={() => setConfirmModal(true)} />
                                </div>
                                {confirmModal && (
                                    <div className="fixed bg-[rgba(0,0,0,0.1)] w-full top-0 left-0 h-full backdrop-blur-sm z-50">
                                        <div className="flex justify-center items-center h-full">
                                            <div
                                                className={`transition-all ease-in-out duration-300 py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50 text-black'
                                                    } `}
                                            >
                                                <h2 className="font-bold text-2xl mb-5">Confirm delete?</h2>
                                                <h3 className="text-lg">This action cannot be undone, are you sure?</h3>
                                                <div className="flex gap-5 mt-10 justify-end">
                                                    <Button onClick={() => handleModalClick(trainer._id)} text={'Confirm'} />
                                                    <Button onClick={() => setConfirmModal(false)} text={'Cancel'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                    <div className='flex w-full justify-end py-2'>
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
                    </div>
                </div>
                {addModal === true && <AddTrainerForm addModal={addModal} setAddModal={setAddModal} token={token} getTrainers={getTrainers} />}
            </div>
        </div>
    );
}
