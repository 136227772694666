import React, { useState, useContext, useEffect } from 'react'
import pfp from '../../../../../assets/images/Default_pfp.svg.png';
import Button from '../../../../Static/Button';
import toast from 'react-hot-toast';
import axios from 'axios';
import { MdCancel, MdClose, MdEdit, MdSaveAlt } from 'react-icons/md';
import { myContext } from '../../../../../Context';

export default function ProfileDetails({ token, profile, getUserDetails, setProfile, setFormData, formData }) {
    const [editMode, setEditMode] = useState(false);
    const { themeMode } = useContext(myContext);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    const getCategories = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/trainer/categories', {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setCategories(res.data.categories);
                setSubcategories(res.data.subcategories);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSaveProfile = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.put(
                process.env.REACT_APP_ENDPOINT + '/auth/changeProfile',
                formData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.status === 200) {
                await axios.get(process.env.REACT_APP_ENDPOINT + '/auth/logout', {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                localStorage.removeItem("jwtToken");
                window.location.href = "/";
            } else if (res.status === 201) {
                await getUserDetails();
                toast.success('Profile updated successfully');
                setEditMode(!editMode);
            }
            else {
                toast.error('Error changing password');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    const customSyle = `border-2 rounded-xl px-3 py-1 ${editMode === true ? 'text-black' : themeMode === 'dark' ? 'text-white' : 'text-black'}`;

    return (
        <div className='flex gap-10 px-5 pt-10 pb-5'>
            <div className={`transition-all ease-in-out duration-300 flex flex-col gap-2 justify-center items-center shadow-md p-6 rounded-2xl w-[350px] h-full ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-neutral-100'}`}>
                <p className='text-center mb-5 text-2xl'>Profile</p>
                <img src={formData.profilePhoto ? formData.profilePhoto : pfp} className='w-[150px] h-[150px] rounded-full'></img>
                <p className='text-xl'>{formData.firstName} {formData.lastName}</p>
                {formData.dob ? <>
                    <p className='text-xl'>Age: {new Date().getFullYear() - new Date(formData.dob).getFullYear()} years</p>
                    <label className='text-xl'>DoB: {formData.dob.split('T')[0]}</label>
                </> : null}
                {formData.type === 'main' ? <p className='text-xl'>Account type: Main</p> : null}
            </div>
            <div className=' w-full'>
                <div className={`transition-all ease-in-out duration-300 shadow-md p-10 rounded-2xl w-full flex flex-col gap-2 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-neutral-100'}`}>
                    <div className='flex justify-between text-2xl'>
                        <p className=' mb-5'>Edit Details</p>
                        <Button text={editMode ? <MdClose /> : <MdEdit />} onClick={() => setEditMode(!editMode)} />
                    </div>
                    <div className='flex gap-5'>
                        <div className='flex flex-col gap-2 w-full'>
                            <label className='text-xl'>First Name:</label>
                            <input type='text'
                                className={customSyle}
                                name='firstName'
                                value={formData.firstName}
                                disabled={!editMode}
                                onChange={handleChange}
                            />
                            {formData.type !== 'main' ?
                                <>
                                    <label className='text-xl'>Last Name:</label>
                                    <input type='text'
                                        className={customSyle}
                                        name='lastName'
                                        value={formData.lastName}
                                        disabled={!editMode}
                                        onChange={handleChange}
                                    />
                                </> : null}

                            <label className='text-xl'>Email:</label>
                            <input type='email'
                                className={customSyle}
                                name='email'
                                value={formData.email}
                                disabled={!editMode}
                                onChange={handleChange}
                            />
                            {formData.type !== 'main' ?
                                <>
                                    <label className='text-xl'>Phone Number:</label>
                                    <input type='text'
                                        className={customSyle}
                                        name='phoneNumber'
                                        onKeyDown={(e) => {
                                            if (isNaN(Number(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.phoneNumber}
                                        disabled={!editMode}
                                        onChange={handleChange}
                                    /></> : null}
                        </div>
                        {formData.type !== 'main' ?
                            <div className='flex flex-col gap-2'>
                                <label className='text-xl'>Category:</label>
                                <select
                                    className={'border-2 rounded-xl px-3 py-1 text-black'}
                                    name='category'
                                    value={formData.category}
                                    disabled={!editMode}
                                    onChange={handleChange}
                                >
                                    <option value=''>Select Category</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                                <label className='text-xl'>Subcategory:</label>
                                <select
                                    className={'border-2 rounded-xl px-3 py-1 text-black'}
                                    name='subcategory'
                                    value={formData.subcategory}
                                    disabled={!editMode}
                                    onChange={handleChange}
                                >
                                    <option value=''>Select Subcategory</option>
                                    {subcategories.map((subcategory) => (
                                        <option key={subcategory._id} value={subcategory}>
                                            {subcategory}
                                        </option>
                                    ))}
                                </select>
                                <label className='text-xl'>Address:</label>
                                <input type='text'
                                    className={customSyle}
                                    name='address'
                                    value={formData.address}
                                    disabled={!editMode}
                                    onChange={handleChange}
                                />
                                <label className='text-xl'>Experience:</label>
                                <input type='text'
                                    className={customSyle}
                                    name='experience'
                                    value={formData.experience}
                                    disabled={!editMode}
                                    onChange={handleChange}
                                />
                            </div>
                            : null}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p className='text-2xl my-5'>Change Password</p>
                        <label className='text-xl'>Current Password:</label>
                        <input type='password'
                            className={customSyle}
                            name='currentPass'
                            value={formData.currentPass}
                            disabled={!editMode}
                            onChange={handleChange} />
                        <label className='text-xl'>New Password:</label>
                        <input type='password'
                            className={customSyle}
                            name='newPass'
                            disabled={!editMode}
                            value={formData.newPass}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='flex justify-end'>
                        {editMode ?
                            (<>
                                <div className='flex justify-end gap-5 mt-7'>
                                    <Button icon={<MdSaveAlt />} text='Save Profile' onClick={handleSaveProfile} />
                                    <Button icon={<MdCancel />} text='Cancel' onClick={() => setEditMode(!editMode)} />
                                </div>
                            </>) : null
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}