import React, { useContext, useEffect, useState } from 'react'
import { myContext } from '../../../../../Context';
import axios from 'axios';
import Button from '../../../../Static/Button';
import toast from 'react-hot-toast';
// import PendingVideos from './PendingVideos';
import AddVideoForm from './AddVideoForm';
import ApprovedVideos from './ApprovedVideos';
import NotApprovedVideos from './NotApprovedVideos';

function VideoList({ token }) {
    const { themeMode } = useContext(myContext);
    const [currentTab, setCurrentTab] = useState('Approved');
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className={`w-full py-24 px-7 h-full min-h-screen`}>
            <div className={`transition-all ease-in-out duration-300 px-9 py-7 rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50'}`}>
                <div className='flex justify-between items-center'>
                    <h1 className='text-3xl font-bold'>{currentTab === 'Approved' ? 'Approved videos' : 'Pending Approval'}</h1>
                    <div className='flex justify-center items-center'>
                        <Button text='Upload Video' onClick={() => setOpenModal(!openModal)} className='px-5 py-2 rounded-xl bg-green-500 text-white ml-5' />
                    </div>
                </div>
                <div className='flex gap-5 justify-center items-center text-2xl mt-5'>
                    <p className={`${currentTab === 'Unapproved' ? 'text-[rgb(224,92,19)] border-b-2 pb-2 border-[rgb(224,92,19)]' :
                        `transition-all ease-in-out duration-300 border-b-2 py-1 cursor-pointer 
                   ${themeMode === 'dark' ? 'text-white' : ''}`}`} onClick={() => setCurrentTab('Unapproved')}>Pending Approval</p>
                    <p className={`${currentTab === 'Approved' ? 'text-[rgb(224,92,19)] border-b-2 pb-2 border-[rgb(224,92,19)]' :
                        `transition-all ease-in-out duration-300 border-b-2 py-1 cursor-pointer 
                   ${themeMode === 'dark' ? 'text-white' : ''}`}`} onClick={() => setCurrentTab('Approved')}>Approved Videos</p>
                </div>
                <hr className='my-10' />
                {currentTab === 'Approved' ?
                    <ApprovedVideos token={token} />
                    :
                    <NotApprovedVideos token={token} />
                }
                {openModal && <AddVideoForm setOpenModal={setOpenModal} token={token} setCurrentTab={setCurrentTab} />}
            </div>
        </div>
    )
}

export default VideoList