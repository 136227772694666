import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "../../Static/Button";

export default function Login() {
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData({ ...formData, [name]: inputValue });
  };

  const handleSubmit = async (e) => {
    console.log(process.env.REACT_APP_ENDPOINT + '/auth/login')
    e.preventDefault();
    try {
      const response = await axios.post(process.env.REACT_APP_ENDPOINT + '/auth/login',
        { ...formData },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setSuccess(true);
        const jwtToken = response.data.token;

        const expirationTime = formData.rememberMe ? 7 * 24 * 60 * 60 * 1000 : 24 * 60 * 60 * 1000;
        const expirationDate = new Date().getTime() + expirationTime;

        localStorage.setItem("jwtToken", jwtToken);
        localStorage.setItem("jwtTokenExpiration", expirationDate);

        setTimeout(() => {
         setFormData({});
          window.location.href = "/";
        }, 1000);
      } else {
        setSuccess(false);
      }
    } catch (error) {
      setSuccess(false);
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="bg-gray-100 rounded-2xl p-10">
        <div className="w-[400px]">
          <div className="text-4xl font-semibold mb-6 mt-2 text-center">
            Admin Login
          </div>
          {success === null ? (
            <div className="p-2 px-5"></div>
          ) : success === false ? (
            <div className="bg-red-200 text-lg font-semibold p-2 px-5 rounded-lg">
              Invalid credentials
            </div>
          ) : (
            <div className="bg-green-200 text-lg font-semibold p-2 px-5 rounded-lg">
              Login successful
            </div>
          )}
          <form
            className="flex flex-col items-center w-full"
            onSubmit={handleSubmit}
          >
            <div className="mt-5">
              <label className="text-lg font-semibold">Email</label>
              <input
                name="email"
                className="w-[380px] mt-2 p-1 rounded-lg"
                type="text"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="mt-5">
              <label className="text-lg font-semibold">Password</label>
              <input
                name="password"
                className="w-[380px] mt-2 p-1 rounded-lg"
                type="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>

            <div className="mt-5">
              <label className="text-lg font-semibold">
                <input
                  name="rememberMe"
                  type="checkbox"
                  checked={formData.rememberMe}
                  onChange={handleChange}
                />
                &nbsp;Remember me
              </label>
            </div>

            <div className="mt-10 font-semibold text-xl">
              <Button text={"Submit"} typeButton={"submit"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
