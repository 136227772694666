import React, { useContext, useState } from 'react';
import ProfileDetails from './ProfileDetails';
import ProfileVideos from './ProfileVideos';
import { myContext } from '../../../../../Context';

function Profile({ token, profile, getUserDetails, setProfile, setFormData, formData }) {
    const [currentTab, setCurrentTab] = useState('profile');
    const { themeMode } = useContext(myContext);

    return (
        <>
            <div className='w-full h-full py-24 px-7'>
                <div className={`transition-all ease-in-out duration-300 p-6 rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(30,30,30)]' : 'bg-slate-50'}`}>
                    <div className='flex justify-center gap-5 mt-2 text-2xl'>
                        {formData.type === 'main' ?
                            <p className={`${currentTab === 'profile' ? 'text-[rgb(224,92,19)] border-b-2 pb-2 border-[rgb(224,92,19)]' : `transition-all ease-in-out duration-300 border-b-2 py-1 cursor-pointer 
                         ${themeMode === 'dark' ? 'text-white' : ''}`}`} onClick={() => setCurrentTab('profile')}>Profile</p>
                            :
                            <>
                                <p className={`${currentTab === 'profile' ? 'text-[rgb(224,92,19)] border-b-2 pb-2 border-[rgb(224,92,19)]' : `transition-all ease-in-out duration-300 border-b-2 py-1 cursor-pointer 
                   ${themeMode === 'dark' ? 'text-white' : ''}`}`} onClick={() => setCurrentTab('profile')}>Profile</p>
                                <p className={`${currentTab === 'videos' ? 'text-[rgb(224,92,19)] border-b-2 pb-2 border-[rgb(224,92,19)]' : `transition-all ease-in-out duration-300 border-b-2 py-1 cursor-pointer 
                   ${themeMode === 'dark' ? 'text-white' : ''}`}`} onClick={() => setCurrentTab('videos')}>My videos</p>
                            </>}
                    </div>
                    {currentTab === 'profile' && <ProfileDetails token={token}
                        profile={profile}
                        getUserDetails={getUserDetails}
                        setProfile={setProfile}
                        setFormData={setFormData}
                        formData={formData}
                    />}
                    {currentTab === 'videos' && <ProfileVideos token={token} />}
                </div>
            </div>
        </>
    )
}

export default Profile