import React from 'react';

function Pagination({ currentPage, setCurrentPage, pageNumbers }) {
    const handleBackClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleForwardClick = () => {
        if (currentPage < pageNumbers.length) {
            setCurrentPage(currentPage + 1);
        }
    }

    const renderPageNumbers = () => {
        const renderedNumbers = [];

        for (let i = 1; i <= pageNumbers.length; i++) {
            if (i === 1 || i === pageNumbers.length || Math.abs(currentPage - i) <= 2) {
                renderedNumbers.push(
                    <li
                        key={i}
                        className={`${currentPage === i ? ' bg-[rgb(224,92,19)] ' : 'bg-slate-300 hover:hover:bg-[rgb(230,120,19)] text-black hover:text-white'}  transition-all ease-in-out duration-300 px-3 py-1 rounded-lg text-lg   `}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </li>
                );
            } else if (Math.abs(currentPage - i) === 3) {
                renderedNumbers.push(
                    <li key={`ellipsis-${i}`} className="bg-slate-300 px-3 py-1 rounded-lg text-lg">
                        ...
                    </li>
                );
            }
        }

        return renderedNumbers;
    };


    return (
        <ul id="page-numbers" className='flex justify-end px-4 gap-3 text-white cursor-pointer'>
            <li className='flex items-center justify-center'>
                <button
                    onClick={handleBackClick}
                    disabled={currentPage === 1}
                    className={`${currentPage === 1 ? "bg-slate-500 cursor-not-allowed" : "bg-[rgb(224,92,19)] hover:hover:bg-[rgb(230,120,19)]"} transition-all ease-in-out duration-300 px-3 py-1 text-center rounded-lg text-lg`}
                >
                    {"<"}
                </button>
            </li>
            {renderPageNumbers()}
            <li className='flex items-center justify-center'>
                <button
                    onClick={handleForwardClick}
                    disabled={currentPage >= pageNumbers.length}
                    className={`${currentPage >= pageNumbers.length ? "bg-slate-500 cursor-not-allowed" : "bg-[rgb(224,92,19)] hover:hover:bg-[rgb(230,120,19)]"}  transition-all ease-in-out duration-300 px-3 py-1 text-center rounded-lg text-lg`}
                >
                    {">"}
                </button>
            </li>
        </ul>
    );
}

export default Pagination;
