import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { myContext } from '../../../../../Context';
import Button from '../../../../Static/Button';
import { MdEdit, MdDeleteForever } from 'react-icons/md';
import { BsArrowsFullscreen } from "react-icons/bs";
import toast from 'react-hot-toast';
import { IoArrowBack } from 'react-icons/io5';

function BannerDetailsPage({ bannerId, token, setEditMode }) {
    const [confirmModal, setConfirmModal] = useState(false);
    const { themeMode } = useContext(myContext);
    const [visibleImageIndex, setVisibleImageIndex] = useState(false);
    const [banner, setBanner] = useState({});
    const [loading, setLoading] = useState(false);

    const toggleImage = (index) => {
        setVisibleImageIndex(index);
    }

    const handleModalClick = (id) => {
        onDelete(id);
        setConfirmModal(!confirmModal);
    };

    const onDelete = async (id) => {
        setLoading(true);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/banner/deleteBanner/${id}`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            toast.success('Banner deleted successfully');
            window.location.reload();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error('Error deleting banner');
            console.error(error);
        }
    }

    const getBannerDetails = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + `/banner/getBanner/${bannerId}`,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.status === 200) {
                setBanner(res.data.banner);
                console.log(res.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getBannerDetails();
    }, [banner])

    return (
        <div className={`h-screen flex flex-col w-full py-4 px-5 `}>
            <div className={`transition-all duration-300 ease-in-out p-8 rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(30,30,30)] text-white' : 'bg-slate-100'}`}>
                <div className="flex justify-between w-full h-14">
                    <p className='text-3xl font-bold mb-10'>Current banner details</p>
                    <div className='flex gap-3 '>
                        <Button onClick={() => window.location.reload()} icon={<IoArrowBack />} className='px-5 py-2 rounded-xl bg-green-500 text-white' />
                        <Button onClick={() => setEditMode({ isActive: true, index: banner._id })} icon={<MdEdit />} />
                        <Button onClick={() => setConfirmModal(true)} icon={<MdDeleteForever />} />
                    </div>
                </div>
                <div className="flex mt-10">
                    <div className="pr-8 relative ">
                        <img
                            className='max-w-[500px] max-h-[400px] rounded-xl'
                            src={banner.uploadedImage}
                            alt="Banner"
                        />
                        <p className='absolute top-5 shadow-lg right-12 text-2xl cursor-pointer transition-all ease-in-out duration-300 hover:scale-110 b'
                            onClick={() => toggleImage(banner._id)}
                        ><BsArrowsFullscreen /></p>
                    </div>
                    <div className="w-full">
                        <p className="mb-2 text-xl font-semibold">Title:</p>
                        <p className="mb-2">{banner.title}</p>
                        <p className="mb-2 text-xl font-semibold">Description:</p>
                        <p className="mb-2 max-w-[700px] break-words">{banner.description}</p>
                        <p className="mb-2 text-xl font-semibold">Banner Type:</p>
                        <p className="mb-2 capitalize">{banner.bannerType}</p>
                        <p className="mb-2 text-xl font-semibold">Is banner active?: {banner.isActive ? 'Yes' : 'No'}</p>
                        {banner.link ? (
                            <>
                                <p className="mb-2 text-xl font-semibold">Link to:</p>
                                <p className="mb-2">{banner.link}</p>
                            </>
                        ) : (<></>)}
                    </div>
                </div>
                {visibleImageIndex === banner._id && (
                    <div className='fixed bg-[rgba(0,0,0,0.5)] w-full h-screen top-0 left-0 backdrop-blur-sm p-20 z-40 overflow-y-auto'>
                        <div className='transition-all ease-in-out duration-300 absolute top-5 right-10 text-3xl px-3 py-1 cursor-pointer 
                                            border-white border rounded-full text-white hover:bg-red-500 hover:border-transparent hover:scale-110' onClick={() => setVisibleImageIndex(false)}>X</div>
                        <img
                            className='w-full max-h-full object-cover'
                            src={banner.uploadedImage}
                            alt="Banner"
                        />
                    </div>
                )}
                {confirmModal && (
                    <div className='fixed bg-[rgba(0,0,0,0.1)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                        <div className='flex justify-center items-center h-full'>
                            <div className={`py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50 text-black'} `}>
                                <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                                <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                                <div className='flex gap-5 mt-10 justify-end'>
                                    {
                                        loading === true ? (
                                            <>
                                                <Button disabled={true} text={"Please wait..."} />
                                                <Button disabled={true} text={"Cancel"} />
                                            </>
                                        ) : (
                                            <>
                                                <Button onClick={() => handleModalClick(banner._id)} text={"Confirm"} />
                                                <Button onClick={() => setConfirmModal(false)} text={"Cancel"} />
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BannerDetailsPage;
