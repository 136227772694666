import React, { useState, useEffect, useContext } from 'react'
import Button from '../../../../Static/Button'
import axios from 'axios'
import toast from 'react-hot-toast'
import { MdClose, MdSaveAlt } from 'react-icons/md'
import { myContext } from '../../../../../Context'

function EditForm({ token, setEditMode, getBanners, editMode }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        bannerType: '',
        link: '',
        isActive: false,
        bannerImage: null,
        currentBannerImage: null,
    });
    const fileInputRef = React.useRef(null);
    const { themeMode } = useContext(myContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBanner = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/banner/getBanner/${editMode.index.index}`, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                const bannerData = response.data.banner;
                setFormData(prevFormData => ({
                    ...prevFormData,
                    title: bannerData.title,
                    description: bannerData.description,
                    bannerType: bannerData.bannerType,
                    link: bannerData.link,
                    isActive: bannerData.isActive,
                    currentBannerImage: bannerData.uploadedImage,
                }));

            } catch (error) {
                console.error('Error fetching banner data:', error);
            }
        };

        if (editMode.isActive) {
            fetchBanner();
        }
    }, [editMode.isActive]);


    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    const handleImageDeselect = () => {
        setSelectedImage(null);
        setFormData((prevData) => ({
            ...prevData,
            bannerImage: null,
        }));
        fileInputRef.current.value = null;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log(file);

        setFormData((prevData) => ({
            ...prevData,
            bannerImage: file,
        }), () => {
        });

        setSelectedImage(file);
    };

    const onEdit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const editedBanner = editMode.index.index;
        const requestData = {
            title: formData.title ?? editedBanner?.title,
            description: formData.description ?? editedBanner?.description,
            bannerType: formData.bannerType ?? editedBanner?.bannerType,
            isActive: formData.isActive ?? editedBanner?.isActive,
            link: formData.link ?? editedBanner?.link,
            bannerImage: formData.bannerImage ?? editedBanner,
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/banner/editBanner/${editedBanner}`,
                requestData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Banner edited successfully");
            setEditMode({ isActive: false, index: null });
            getBanners();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error submitting data:", error);
        }
    };
    return (
        <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-40'>
            <div className='flex justify-center items-center h-full'>
                <form onSubmit={onEdit} className={`w-[800px] px-7 py-5 shadow-2xl rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-100'}`}>
                    <div className='flex justify-between'>
                        <h3 className='text-3xl mb-5 ml-3 font-bold'>Edit banner</h3>
                        <div className='w-1 mr-10 text-xl'>
                            <Button text={<MdClose />} onClick={() => setEditMode(false)} />
                        </div>
                    </div>                        <div className='flex justify-between px-3'>
                        <div className='flex flex-col w-[400px]'>
                            <div className="mb-3.5">
                                <label className="block font-bold text-lg mb-3.5" htmlFor="title">
                                    Title
                                </label>
                                <input
                                    className="shadow text-lg appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-black"
                                    id="title"
                                    type="text"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    name="title"
                                />
                            </div>
                            <div className="mb-3.5">
                                <label className="block text-lg font-bold mb-3.5" htmlFor="description">
                                    Description
                                </label>
                                <textarea
                                    className="shadow h-20 text-lg appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-black"
                                    id="description"
                                    type="text"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    name="description"
                                />
                            </div>
                            <div className="mb-3.5">
                                <label className="block text-lg font-bold mb-3.5" htmlFor="bannerType">
                                    Banner Type
                                </label>
                                <select
                                    className="shadow appearance-none border text-lg rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-black"
                                    id="bannerType"
                                    name="bannerType"
                                    value={formData.bannerType}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Banner Type</option>
                                    <option value="shop">Shop</option>
                                    <option value="video">Video</option>
                                    <option value="explore">Explore</option>
                                    <option value="link">Link</option>
                                </select>
                            </div>
                            <div className="mb-3.5">
                                <label className="block font-bold text-lg mb-2" htmlFor="title">
                                    Link
                                </label>
                                <input
                                    className="shadow text-lg appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-black"
                                    id="link"
                                    type="text"
                                    value={formData.link}
                                    onChange={handleInputChange}
                                    name="link"
                                />
                            </div>
                            <div className="mt-2 mb-3.5 flex justify-between">
                                <label className="block text-lg font-bold mb-3.5" htmlFor="isActive">
                                    Current Status: {formData.isActive ? 'Active' : 'Inactive'}
                                </label>
                                <button
                                    type='button'
                                    className={`w-16 h-8 rounded-full transition-all ease-in-out duration-300 p-1 ${formData.isActive ? 'bg-[rgb(224,92,19)]' : 'bg-gray-500'
                                        }`}
                                    onClick={() =>
                                        handleInputChange({
                                            target: { name: 'isActive', value: !formData.isActive },
                                        })
                                    }
                                >
                                    <div
                                        className={`w-7 h-6 rounded-full shadow-md transition-all ease-in-out duration-300 ${formData.isActive ? 'transform translate-x-7' : ''
                                            } bg-white border-2 border-gray-300`}
                                    ></div>
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col max-w-[280px] items-start pt-5'>
                            {formData ?
                                <div className="mb-3">
                                    <img
                                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                                        src={formData.currentBannerImage}
                                        alt="Banner"
                                    />
                                    <label className="block text-lg text-center font-bold my-2" htmlFor="bannerImage">
                                        Current banner image
                                    </label>
                                </div> : <></>}
                            {selectedImage && (
                                <div className='relative mt-5'>
                                    <div className='cursor-pointer absolute right-0 px-2 shadow-xl bg-red-500 transition-all ease-in-out duration-300 text-2xl hover:scale-105 text-white rounded-full'
                                        onClick={handleImageDeselect}
                                    >
                                        X                                        </div>
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        alt="Selected"
                                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />
                                </div>
                            )}
                            <div className='flex flex-col gap-5 h-full w-full mt-2'>
                                {selectedImage ?
                                    <label className="block text-lg font-bold text-center" htmlFor="bannerImage">
                                        New banner image
                                    </label> :
                                    <label
                                        className={`transition-all ease-in-out duration-300 
                                    w-full h-10 py-5 mt-5 flex justify-center items-center rounded-2xl border-2 border-dashed hover:rounded-xl cursor-pointer ${selectedImage ? 'hidden' : ''
                                            }`}
                                        htmlFor="bannerImage"
                                    >
                                        <p className="text-lg">Select new image</p>
                                    </label>
                                }
                                <input
                                    type="file"
                                    accept="image/*"
                                    className={`text-transparent hidden`}
                                    onChange={handleImageChange}
                                    name="bannerImage"
                                    id="bannerImage"
                                    ref={fileInputRef}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        {
                            loading === true ? (
                                <Button disabled={true} text={"Please wait..."} />
                            ) : (
                                <Button icon={<MdSaveAlt />} text={"Save banner"} typeButton={"submit"} />
                            )
                        }
                    </div>
                </form>
            </div >
        </div >
    );
}

export default EditForm