import React, { useState, useContext } from 'react';
import Button from '../../../../Static/Button';
import Pagination from '../../../../Static/Pagination';
import { myContext } from '../../../../../Context';
import { MdAdd, MdDeleteForever, MdEdit } from 'react-icons/md';
import BannerDetailsPage from './BannerDetails';
import { BsArrowsFullscreen } from 'react-icons/bs';

function BannerList({ banners, handleDelete, setCreateMode, createMode, setEditMode, token, loading }) {
    const [visibleImageIndex, setVisibleImageIndex] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const { themeMode } = useContext(myContext);
    const [selectedBannerId, setSelectedBannerId] = useState(null);
    const bannersPerPage = 10;

    const toggleImage = (index) => {
        setVisibleImageIndex(visibleImageIndex === index ? null : index);
    }

    const handleModalClick = (id) => {
        handleDelete(id);
        setConfirmModal(!confirmModal);
    };

    const indexOfLastBanner = currentPage * bannersPerPage;
    const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
    const currentBanners = banners.slice(indexOfFirstBanner, indexOfLastBanner);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(banners.length / bannersPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleBannerClick = (id) => {
        setSelectedBannerId(id);
    }

    return (
        <>
            {selectedBannerId ? (
                <BannerDetailsPage
                    token={token}
                    bannerId={selectedBannerId}
                    setEditMode={setEditMode}
                    setConfirmModal={setConfirmModal}
                    toggleImage={toggleImage}
                    handleDelete={handleDelete}
                    onClose={() => setSelectedBannerId(null)}
                />
            ) :
                <div className={`${currentBanners.length <= 1 ? 'h-screen' : 'h-full'} flex flex-col w-full py-4 px-5 `}>
                    <div className={`transition-all duration-300 ease-in-out p-8 rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(30,30,30)] text-white' : 'bg-slate-100'}`}>
                        <div className="flex justify-between w-full">
                            <p className='text-3xl font-bold mb-10'>Current Banners</p>
                            <div>
                                <Button onClick={() => setCreateMode(!createMode)} icon={<MdAdd />} text={"Add Banner"} />
                            </div>
                        </div>
                        {banners && banners.length > 0 ? (
                            <>
                                {
                                    currentBanners.map((banner, index) => (
                                        <div key={banner._id} className={`transition-all duration-300 ease-in-out banner-item 
                                px-10 py-10 rounded-xl shadow-lg mb-6 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-50'}`}>
                                            <div className='flex gap-10'>
                                                <div className="pr-8 relative ">
                                                    <img
                                                        className='max-w-[350px] max-h-[200px] rounded-xl'
                                                        src={banner.uploadedImage}
                                                        alt="Banner"
                                                    />
                                                    <p className='absolute top-5 shadow-lg right-12 text-2xl cursor-pointer transition-all ease-in-out duration-300 hover:scale-110 b'
                                                        onClick={() => toggleImage(index)}
                                                    ><BsArrowsFullscreen /></p>
                                                </div>
                                                <div className='flex justify-between w-full'>
                                                    <div className='flex flex-col items-start gap-2 cursor-pointer w-full' onClick={() => handleBannerClick(banner._id)}>
                                                        <p className='text-2xl font-semibold'>{banner.title}</p>
                                                        <p className='text-xl font-semibold max-w-[350px] break-words'>{banner.description}</p>
                                                        <p className='text-2xl font-semibold'>Active?: <span className='text-lg'>{banner.isActive === 'true' ? 'Yes' : 'No'}</span></p>
                                                    </div>
                                                    <div className='flex gap-3 h-[50px]'>
                                                        <Button onClick={() => setEditMode({ isActive: true, index: banners[index]._id })} icon={<MdEdit />} />
                                                        <Button onClick={() => setConfirmModal(true)} icon={<MdDeleteForever />} />
                                                    </div>
                                                </div>
                                            </div>
                                            {visibleImageIndex === index && (
                                                <div className='fixed bg-[rgba(0,0,0,0.5)] w-full h-screen top-0 left-0 backdrop-blur-sm p-20 z-40 overflow-y-auto'>
                                                    <div className='transition-all ease-in-out duration-300 absolute top-5 right-10 text-3xl px-3 py-1 cursor-pointer 
                                            border-white border rounded-full text-white hover:bg-red-500 hover:border-transparent hover:scale-110' onClick={() => setVisibleImageIndex(false)}>X</div>
                                                    <img
                                                        className='w-full max-h-full object-cover'
                                                        src={banner.uploadedImage}
                                                        alt="Banner"
                                                    />
                                                </div>
                                            )}
                                            {confirmModal && (
                                                <div className='fixed bg-[rgba(0,0,0,0.1)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                                                    <div className='flex justify-center items-center h-full'>
                                                        <div className={`py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-50'} `}>
                                                            <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                                                            <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                                                            <div className='flex gap-5 mt-10 justify-end'>
                                                                {
                                                                    loading === true ? (
                                                                        <>
                                                                            <Button disabled={true} text={"Please wait..."} />
                                                                            <Button disabled={true} text={"Cancel"} />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Button onClick={() => handleModalClick(banner._id)} text={"Confirm"} />
                                                                            <Button onClick={() => setConfirmModal(false)} text={"Cancel"} />
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                }
                                < Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
                            </>
                        ) : (
                            <div className='flex justify-center items-center h-sreen'>
                                <h1 className='text-2xl'>There are no banners, please add one.</h1>
                            </div>
                        )}
                    </div>
                </div>
            }
        </>
    );
}

export default BannerList;
