import React, { useState, useContext } from 'react';
import Button from '../../../../Static/Button';
const { myContext } = require("../../../../../Context");

const TrainerSearchModal = ({ trainers, onTrainerSelect, onClose, setIsModalOpen }) => {
    const { themeMode } = useContext(myContext);
    const [internalSearchTerm, setInternalSearchTerm] = useState('');

    const handleSearch = (e) => {
        const value = e.target.value;
        setInternalSearchTerm(value);
    };

    const handleTrainerClick = (trainer) => {
        onTrainerSelect(trainer);
    };

    const filteredTrainers = trainers.filter(
        (trainer) =>
            trainer.firstName.toLowerCase().includes(internalSearchTerm.toLowerCase()) ||
            trainer.lastName.toLowerCase().includes(internalSearchTerm.toLowerCase())
    );

    return (
        <div className='absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center text-black z-40'>
            <div className={`transition-all ease-in-out duration-300 p-10 w-full h-[350px] overflow-y-auto shadow-orange-900 shadow-2xl  rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50 text-black'}`}>
                <div className='flex flex-col justify-between h-full w-full'>
                    <input
                        type='text'
                        placeholder='Search for trainers'
                        value={internalSearchTerm}
                        onChange={handleSearch}
                        className='px-3 py-1 rounded-md border w-full text-black'
                    />
                    {internalSearchTerm === '' ? (
                        <p className='text-center text-lg'>Input in search field</p>
                    ) : (
                        <ul className='py-5'>
                            {filteredTrainers.map((trainer) => (
                                <li className={`transition-all ease-in-out duration-300 flex justify-start items-center cursor-pointer rounded-2xl p-3 gap-5 mt-5 ${themeMode === 'dark' ? 'hover:bg-slate-500' : 'hover:bg-slate-200'}`} key={trainer._id} onClick={() => handleTrainerClick(trainer)}>
                                    <img src={trainer.photoURL} alt='trainer' className='w-10 h-10 rounded-full' />
                                    {trainer.firstName} {trainer.lastName}
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className='flex justify-end w-full pb-5'>
                    <Button text='Close' onClick={typeof onClose === 'function' ? onClose : () => setIsModalOpen(false)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainerSearchModal;
