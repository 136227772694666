import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/Pages/Registration/Login';
import { Toaster } from 'react-hot-toast';
import SideNavbar from './Components/Pages/Dashboard/SideNavbar';
import { myContext } from './Context';

const DashboardLayout = () => (
  <>
    <SideNavbar />
  </>
);

export default function App() {
  const jwtToken = localStorage.getItem("jwtToken");
  const expirationTime = localStorage.getItem("jwtTokenExpiration");
  const [logedIn, setLogedIn] = useState(false);
  const [themeMode, setThemeMode] = useState(localStorage.getItem("themeMode") || 'light');
  localStorage.setItem("themeMode", themeMode);

  const toggleTheme = () => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light');
  }

  useEffect(() => {
    if (jwtToken) {
      const expirationTime = localStorage.getItem("jwtTokenExpiration");
      const currentTime = new Date().getTime();

      if (expirationTime && currentTime >= expirationTime) {
        setLogedIn(false);
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("jwtTokenExpiration");
      } else {
        setLogedIn(true);
      }
    } else {
      setLogedIn(false);
    }
  }, [jwtToken]);



  return (
    <myContext.Provider value={{ themeMode, toggleTheme }}>
      <div><Toaster /></div>
      <div className={themeMode === "light" ? "bg-white" : "bg-[rgb(50,50,50)]"}>
        <Router>
          <Routes>
            {logedIn ? (
              <Route path="/" element={<DashboardLayout />} />
            ) : (
              <Route path="/" element={<Login />} />
            )}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </div>
    </myContext.Provider>
  );
}
