import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BannerList from './BannerList';
import toast from 'react-hot-toast';
import CreateForm from './CreateForm';
import EditForm from './EditForm';

function ManageBanner({ token }) {
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState({
    isActive: false,
    index: null,
  });
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  const onDelete = async (id) => {
    setLoading(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/banner/deleteBanner/${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      toast.success('Banner deleted successfully');
      getBanners();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Error deleting banner');
      console.error(error);
    }
  }

  const getBanners = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/banner/getBanners`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
      setBanners(response.data.banners);
    } catch (error) {
      toast.error('Error deleting banner');
      console.error('Error getting banners:', error);
    }
  }

  useEffect(() => {
    getBanners();
  }, []);

  return (<>
    <div className="flex flex-col justify-center items-center w-full h-full py-20 px-2">
      <BannerList
        banners={banners}
        token={token}
        setCreateMode={setCreateMode}
        createMode={createMode}
        setEditMode={(index) => setEditMode({ isActive: true, index })}
        handleDelete={onDelete}
        loading={loading}
      />
    </div>
    {editMode.isActive ? (
      <EditForm
        setEditMode={setEditMode}
        editMode={editMode}
        getBanners={getBanners}
        loading={loading}
        token={token}
      />
    ) : (
      <></>
    )}
    {createMode ? <CreateForm setCreateMode={setCreateMode} token={token} getBanners={getBanners} /> : <></>}
  </>
  );
}

export default ManageBanner;
